import React, { useMemo } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { ChatChannelProjectHeader } from '@gtintel/platform-atoms';
import { Container, Header } from './styles';
const ChatChannelProjectCollapse = ({
  expandedPIds,
  project,
  projectId,
  projectName,
  conversations,
  renderConversation,
  routeRole,
  toggleGroupCollapse,
  unreadCount
}) => {
  const key = `panel_${projectId}_${projectName?.replaceAll(' ', '_')}`;
  const isExpanded = useMemo(() => expandedPIds.has(projectId), [expandedPIds, projectId]);
  const header = <ChatChannelProjectHeader conversations={conversations} project={project} projectName={projectName} routeRole={routeRole} unreadCount={unreadCount} />;
  return <Container expandIcon={({
    isActive
  }) => <CaretRightOutlined style={{
    color: '#ffc000',
    fontSize: '25px'
  }} rotate={isActive ? 90 : 0} />} defaultActiveKey={isExpanded ? [key] : undefined} onChange={() => toggleGroupCollapse(projectId)}>
      <Header header={header} key={key}>
        {conversations.map(conv => renderConversation(conv))}
      </Header>
    </Container>;
};
export default ChatChannelProjectCollapse;