import styled from 'styled-components';
import { Button, Form, Upload } from 'antd';
import TextareaAutosize from 'react-textarea-autosize';
export const StyledForm = styled(Form)`
  column-gap: 15px;
  display: flex;
  padding: 0 17px !important;
  position: relative;

  span.anticon.anticon-loading {
    display: none;
  }

  .ant-upload-list {
    background: rgba(255, 255, 255, 0.9);
    border-top-right-radius: 5px;
    bottom: 40px;
    left: 0;
    padding-bottom: 8px;
    padding-left: 30px;
    position: absolute;
  }
`;
export const StyledTextareaAutosize = styled(TextareaAutosize)`
  background: #f2f4f6;
  border-radius: 25px;
  border: none;
  color: black;
  font-size: 14px;
  font-weight: 400;
  height: 1rem;
  outline: none;
  padding: 9px 14px;
  resize: none;
  width: 100%;
`;
export const SendButton = styled(Button)`
  color: #ffff;
  height: 40px;
  margin-left: auto;

  &:hover,
  &:focus {
    color: #fff;
  }
`;
export const FileUpload = styled(Upload)`
  margin-top: 8px;
`;
export const FileUploadLabel = styled.label`
  cursor: pointer;

  .attach-icon {
    margin-top: 8px;
  }
`;