import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { Col, Input } from 'antd';
import { RouteRolesEnum } from '@gtintel/platform-types';
import { DEBOUNCE_WAIT_TIME } from '@gtintel/platform-constants';
import ChatChannels from '../ChatChannels';
import { NewMessageIcon } from '../../molecules/NewMessageIcon';
import * as S from './styles';
const searchConversations = (conversations, searchKey) => {
  const key = searchKey.toLowerCase();
  return conversations.filter(conversation => {
    const convAttrs = conversation.attributes;
    const authors = convAttrs.member?.filter(m => m.name)?.map(m => m.name);
    const matchAuthor = authors.some(a => a.toLowerCase().includes(key));
    return matchAuthor || convAttrs.project_name?.toLowerCase()?.includes(key);
  });
};
const ChatList = ({
  activeConversationSid,
  conversations,
  currentUserRole,
  onClickNewMsgBtn,
  openConversation,
  starChannel,
  user
}) => {
  const isAdmin = currentUserRole === RouteRolesEnum.ADMIN;
  const [searchKey, setSearchKey] = useState('');
  const onChangeSearch = debounce(({
    target: {
      value
    }
  }) => setSearchKey(value), DEBOUNCE_WAIT_TIME);
  const filteredConversations = conversations === null ? null : searchConversations(conversations, searchKey);
  return <Col span="10">
      <S.Container>
        <S.ListHeader>
          <p className="ant-dropdown-link direct-messages">Direct Messages</p>
          {isAdmin && <NewMessageIcon onClick={onClickNewMsgBtn} />}
        </S.ListHeader>
        <S.ListSearch>
          <Input.Search placeholder="Search Messages" onChange={onChangeSearch} />
        </S.ListSearch>
        <ChatChannels activeConversationSid={activeConversationSid} conversations={filteredConversations} currentUserRole={currentUserRole} openConversation={openConversation} starChannel={starChannel} user={user} />
      </S.Container>
    </Col>;
};
export default ChatList;