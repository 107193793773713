import React from 'react';
function AttachIcon({
  className = '',
  color = '#4D7CFE',
  width = 21.458,
  height = 23.42
}) {
  return <svg className={`${className} attach-icon`} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21.458 23.42">
      <path id="paperclip_icon" data-name="paperclip icon" d="M21.525,21.7a5.086,5.086,0,0,0-1.533-3.6l-8.9-8.9A3.2,3.2,0,0,0,8.8,8.228a2.97,2.97,0,0,0-3,3,3.228,3.228,0,0,0,.966,2.284L13.049,19.8a.492.492,0,0,0,.337.153,2.179,2.179,0,0,0,1.2-1.2.492.492,0,0,0-.153-.337L8.144,12.136a1.345,1.345,0,0,1-.383-.9A1,1,0,0,1,8.788,10.2a1.367,1.367,0,0,1,.92.368l8.9,8.9a3.187,3.187,0,0,1,.966,2.222,2.207,2.207,0,0,1-2.268,2.268A3.216,3.216,0,0,1,15.087,23L3.194,11.094A3.936,3.936,0,0,1,2.029,8.32,3.87,3.87,0,0,1,5.891,4.4a4.017,4.017,0,0,1,2.774,1.18l9.288,9.3a.5.5,0,0,0,.353.153,2.125,2.125,0,0,0,1.18-1.18.492.492,0,0,0-.153-.337L10.06,4.227A5.969,5.969,0,0,0,5.876,2.5,5.783,5.783,0,0,0,.067,8.335,5.963,5.963,0,0,0,1.8,12.489L13.708,24.382a5.15,5.15,0,0,0,3.6,1.533A4.137,4.137,0,0,0,21.525,21.7Z" transform="translate(-0.067 -2.495)" fill={color} />
    </svg>;
}
export default AttachIcon;