import React, { useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { Divider, Modal } from 'antd';
import { SearchInput } from '@gtintel/platform-atoms';
import UserList from '../UserList';
const UserListModal = ({
  currentUserId,
  hideSelf,
  isVisible,
  onUserClick,
  adminMessageStore
}) => {
  const handleChangeSearchText = useCallback(keyword => {
    adminMessageStore.changeSearchText(keyword);
  }, [adminMessageStore]);
  return <Modal width={500} bodyStyle={{
    height: 500,
    overflow: 'auto',
    padding: 24
  }} style={{
    textAlign: 'center'
  }} visible={isVisible} onOk={hideSelf} onCancel={hideSelf} className="partner-modal" footer={null}>
      <SearchInput className="message-user-list-search" placeholder="Search Messages" changeSearchText={handleChangeSearchText} />
      <Divider />
      <UserList currentUserId={currentUserId} onUserClick={onUserClick} store={adminMessageStore} />
    </Modal>;
};
const WrappedUserListModal = props => {
  const UserListModalWithStore = inject('adminMessageStore')(observer(UserListModal));
  return <UserListModalWithStore {...props} />;
};
export default WrappedUserListModal;