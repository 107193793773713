import styled from 'styled-components';
export const ButtonContainer = styled.div`
  border: 0;
  background: transparent;
  display: flex;

  a {
    display: block;
    margin-top: 7px;

    .fa-minus {
      color: white;
      font-size: 18px;
    }

    &:first-child i {
      color: #fff;
      font-size: 24px;
      padding-right: 6px;
    }
  }

  i.la.la-arrow-up {
    color: #fff !important;
    font-size: 20px;
    font-weight: bold;
    transform: rotate(45deg);
  }
`;
export const ButtonMainColors = styled.a`
  i,
  svg {
    color: #ffffff !important;
    font-size: 24px;
    margin-left: 4px;
    margin-right: 4px;
  }
`;
export const BodyDiv = styled.div`
  @res_991: ~"only screen and (max-width: @{screen-sm-max})";

  background: white;
  display: none;
  height: 689px;
  max-height: 70vh;
  transition: 1s;
  width: 647px;

  @media @res_991 {
    height: 300px;
  }

  .active > & {
    display: block !important;
  }
`;
export const ContainerDiv = styled.div`
  background: white;
  border-radius: 14px 14px 0 0;
  box-shadow: 0px 1px 4px 0px #bdbcbc;
  margin: 0 4.5px;
  overflow: hidden;

  ${({
  isHidden
}) => isHidden ? 'display: none;' : 'display: block;'}
`;
export const FooterDiv = styled.div`
  background: #ffffff;
  display: none;
  min-height: 44px;
  padding-bottom: 4px;

  .active > & {
    display: block !important;
  }
`;
export const HeadDiv = styled.div`
  align-items: center;
  border-radius: 14px 14px 0 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: -1px;
  padding: 10px 12px 10px 12px;

  @media @desktop {
    min-width: 170px;
  }

  @media @large {
    min-width: 289px;
  }
`;
export const TitleDiv = styled.div`
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  z-index: 2;
`;