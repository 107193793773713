import React, { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import moment from 'moment';
import { LoadingSpinner2 } from '@gtintel/platform-atoms';
import ChatMessage from './ChatMessage';
import * as S from './styles';
function formatDate(dateStr) {
  const date = moment(dateStr);
  if (date.year() === moment().year()) {
    return date.format('MMM Do');
  } else {
    return date.format('MMM Do, YYYY');
  }
}
// Main area that displays the actual chat messages. Note that this component
// is rendered within both the ChatPopup and the Messages tab.
const ChatContent = ({
  endRef,
  getMoreMessages,
  isPopup,
  messages,
  twilioClient,
  user
}) => {
  const [isLoading, setIsLoading] = useState(messages === null);
  // temp attachment URLs fetched from twilio. keys = `Media` SIDs
  const [attachmentUrls, setAttachmentUrls] = useState(new Map());
  useEffect(() => {
    const getAttachmentUrls = async () => {
      const mediaSids = messages.flatMap(m => m.twilio.attachedMedia).map(m => m.sid);
      // getTemporaryContentUrlsForMediaSids raises error if arg is []:
      if (!mediaSids.length) return;
      const urls = await twilioClient.getTemporaryContentUrlsForMediaSids(mediaSids);
      setAttachmentUrls(prev => new Map([...prev, ...urls]));
    };
    getAttachmentUrls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length, twilioClient]);
  const loadMoreMessages = async () => {
    setIsLoading(true);
    try {
      await getMoreMessages();
    } finally {
      setIsLoading(false);
    }
  };
  const handleScroll = e => {
    e.preventDefault();
    if (e.currentTarget.scrollTop === 0) {
      loadMoreMessages();
    }
  };
  const messagesByDate = Object.entries(groupBy(messages, message => {
    // "YYYY-MM-DD"
    return message.twilio.dateCreated.toISOString().substring(0, 10);
  })).map(([date, messages]) => ({
    date,
    messages
  }));
  return <S.Container onScroll={handleScroll} isPopup={isPopup}>
      {isLoading && <LoadingSpinner2 margin={50} />}

      {messagesByDate.map(({
      date,
      messages
    }) => <div key={date}>
          <S.DateSeparator>
            <S.DateSeparatorLine />
            <S.Date>{formatDate(date)}</S.Date>
            <S.DateSeparatorLine />
          </S.DateSeparator>
          {messages.map(message => <ChatMessage attachmentUrls={attachmentUrls} key={message.twilio.index} message={message} user={user} />)}
        </div>)}

      <div style={{
      float: 'left',
      clear: 'both'
    }} ref={endRef} />
    </S.Container>;
};
export default ChatContent;