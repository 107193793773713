import "antd/lib/avatar/style";
import _Avatar from "antd/lib/avatar";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
var __jsx = React.createElement;
import React from 'react';
import { DEFAULT_AVATAR } from '@gtintel/platform-constants';
var Text = _Typography.Text;
function AvatarName(_ref) {
  var avatar = _ref.avatar,
    name = _ref.name,
    role = _ref.role,
    _ref$color = _ref.color,
    color = _ref$color === void 0 ? '#D0021B' : _ref$color;
  return __jsx("table", {
    className: "avatar-name"
  }, __jsx("tbody", null, __jsx("tr", null, __jsx("td", null, __jsx(_Avatar, {
    src: avatar ? avatar : DEFAULT_AVATAR,
    className: "avatar",
    style: color ? {
      borderColor: color
    } : {}
  })), __jsx("td", null, __jsx("div", {
    className: "name"
  }, __jsx(Text, {
    "data-public": true
  }, name)), __jsx("div", {
    className: "role"
  }, __jsx(Text, {
    "data-public": true,
    style: color ? {
      color: color
    } : {}
  }, role))))));
}
export default AvatarName;