import React from 'react';
import { Avatar } from 'antd';
import { DownloadIcon } from 'react-line-awesome';
import Link from 'next/link';
import * as S from './styles';
const ChatHeader = ({
  currentUserRole,
  image,
  project,
  showPdfModal,
  title
}) => {
  let convTitle = title;
  let projectLink;
  if (project) {
    convTitle += ' - ';
    projectLink = <Link href={`/${currentUserRole}/project/detail/${project.id}`} legacyBehavior>
        {project.name}
      </Link>;
  } else {
    projectLink = null;
  }
  return <S.Container>
      <Avatar src={image} />
      <S.Title>
        {convTitle}
        {projectLink}
      </S.Title>
      <S.Button onClick={showPdfModal}>
        <DownloadIcon />
      </S.Button>
    </S.Container>;
};
export default ChatHeader;