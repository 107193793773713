import React from 'react';
import Link from 'next/link';
import AttachIcon from '../../../../../AttachIcon';
// If attachment is an image, we render it. Else, we show a download link. If
// the URL hasn't been loaded from Twilio yet,  we show a plain filename.
const ChatMessageAttachment = ({
  isSent,
  media,
  url
}) => {
  if (!url) return <>{media.filename}</>;
  const isImage = media.contentType.slice(0, 5) === 'image';
  return <Link prefetch={false} href={url} target="_blank">
      {isImage ? <img alt={media.filename} style={{
      width: '100%',
      maxHeight: '100px'
    }} src={url} /> : <>
          <AttachIcon color={isSent ? '#ffffff' : '#444'} height={14} width={14} />
          {media.filename}
        </>}
    </Link>;
};
export default ChatMessageAttachment;