import React, { useEffect, useState } from 'react';
import { Col, Row, message as antd_message } from 'antd';
import { detailUserAction } from '@gtintel/platform-api';
import { LoadingSpinner2 } from '@gtintel/platform-atoms';
import { TWILIO_ADMIN_IDENTITY } from '@gtintel/platform-constants';
import { RouteRolesEnum } from '@gtintel/platform-types';
import ChatList from './ChatList';
import ConversationPanel from './ConversationPanel';
import UserListModal from '../organisms/UserListModal';
import { getAllConversations } from '../../utils';
const MessageIndex = ({
  activeConversationSid,
  currentUser,
  currentUserRole,
  getMoreMessages,
  isLoadingConversationPanel,
  messages,
  messagesEndRef,
  openConversation,
  project,
  projectId,
  refreshToken,
  selectedConversation,
  setIsActiveConversationStarred,
  setIsLoadingConversationPanel,
  twilioClient
}) => {
  const isAdmin = currentUserRole === RouteRolesEnum.ADMIN;
  const [conversations, setConversations] = useState(null);
  // This will always be false unless current user is an admin:
  const [isUserListModalVisible, setIsUserListModalVisible] = useState(false);
  useEffect(() => {
    if (!twilioClient) return;
    try {
      // NB there may be >1 page of convs to load but we can't show them
      // incrementally because we can't sort or group them (see <ChatChannels>)
      // until all pages are fetched.
      getAllConversations(twilioClient).then(cs => {
        setConversations(cs.filter(c => c.attributes.member && c.lastMessage));
      });
    } catch (e) {
      antd_message.error('Error: could not load conversations.');
      throw e;
    }
  }, [twilioClient]);
  const createNewChatIfAdmin = async userId => {
    if (!userId || !isAdmin) return;
    setIsUserListModalVisible(false);
    const user = await detailUserAction(userId);
    user.identity = TWILIO_ADMIN_IDENTITY;
    const uniqueName = String(Number(currentUser.id) * Number(user.identity));
    try {
      setIsLoadingConversationPanel(true);
      let conversation;
      try {
        conversation = await twilioClient.getConversationByUniqueName(uniqueName);
      } catch {
        conversation = await twilioClient.createConversation({
          uniqueName
        });
      }
      try {
        await conversation.join();
      } finally {
        await openConversation(conversation.sid);
      }
    } catch (error) {
      antd_message.error('Error: could not open conversation');
    } finally {
      setIsLoadingConversationPanel(false);
    }
  };

  // Admin-only:
  const starChannel = async conversation => {
    const convAttrs = conversation.attributes;
    const previousValue = convAttrs.adminStarChannel;
    setIsActiveConversationStarred(!previousValue);
    try {
      await conversation.updateAttributes({
        ...convAttrs,
        adminStarChannel: !previousValue
      });
    } catch (e) {
      setIsActiveConversationStarred(previousValue);
      antd_message.error(`Could not star channel: error ${JSON.stringify(e)}`);
    }
  };
  return <Row>
      <ChatList activeConversationSid={activeConversationSid} conversations={conversations} currentUserRole={currentUserRole} onClickNewMsgBtn={() => isAdmin && setIsUserListModalVisible(true)} openConversation={openConversation} starChannel={starChannel} user={currentUser} />
      <Col span="14">
        {!selectedConversation ? '' : isLoadingConversationPanel ? <LoadingSpinner2 margin={200} /> : <ConversationPanel conversation={selectedConversation} currentUserRole={currentUserRole} getMoreMessages={getMoreMessages} messages={messages} messagesEndRef={messagesEndRef} project={project} projectId={projectId} refreshToken={refreshToken} twilioClient={twilioClient} user={currentUser} />}
        {isAdmin && <UserListModal currentUserId={currentUser?.id} isVisible={isUserListModalVisible} hideSelf={() => setIsUserListModalVisible(false)} onUserClick={userId => createNewChatIfAdmin(userId)} />}
      </Col>
    </Row>;
};
export default MessageIndex;