import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  gap: 8px;

  .ant-badge-count {
    padding: 1px 4px;
  }
`;
export const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 80px;
  height: 64px;
  border: 0;
  border-radius: 4px;
  color: #ffffff;
  background: #fdcd4b;
  z-index: 2;

  &:disabled {
    cursor: not-allowed;
    background-color: #adadad;
  }
  p {
    margin: 2px 0;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    color: #f6fafb;
  }
`;