import React, { useEffect, useRef } from 'react';
import { Avatar, List } from 'antd';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import { LoadingSpinner2 } from '@gtintel/platform-atoms';
import { DEFAULT_AVATAR } from '@gtintel/platform-constants';
import useIntersectionObserver from '../../../useIntersectionObserver';
function displayUserRole(user) {
  if (user?.is_administrator) return 'GTI staff';
  if (user?.dual_account) return 'Client & Partner';
  return user?.is_client ? 'Client' : 'Partner';
}
const UserList = ({
  currentUserId,
  onUserClick,
  store
}) => {
  const {
    userList,
    isLoading,
    hasMore
  } = store;
  const debouncedFetchMore = debounce(store.fetchMoreUsers.bind(store), 300);
  const ref = useRef(null);
  const isBottomVisible = useIntersectionObserver(ref, {
    threshold: 0
  }, false);
  useEffect(() => {
    isBottomVisible && hasMore && debouncedFetchMore();
  }, [isBottomVisible]);
  return <div data-testid="user-list">
      <List itemLayout="horizontal" dataSource={userList} className="listUser" renderItem={user => !user?.is_administrator && currentUserId !== user?.id && <List.Item onClick={() => onUserClick(user?.id)}>
              <List.Item.Meta avatar={<Avatar src={user?.avatar ?? DEFAULT_AVATAR} />} title={<a href="#">{`${user?.first_name} ${user?.last_name}`}</a>} description={displayUserRole(user)} />
            </List.Item>} />
      <div ref={ref} style={{
      width: '100%'
    }}>
        {isLoading && <LoadingSpinner2 margin={10} />}
        {!hasMore && 'End'}
      </div>
    </div>;
};
export default observer(UserList);