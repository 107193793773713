import styled from 'styled-components';
export const Container = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  margin: auto;
`;
export const ListHeader = styled.div`
  border-bottom: 1px solid #e8ecef;
  display: flex;
  height: 56px;
  justify-content: space-between;

  .direct-messages {
    color: #252631;
    font-size: 16px;
    line-height: 56px;
    padding-left: 23px;

    i {
      color: #252631;
      font-size: 14px;
      padding-left: 8px;
    }
  }

  .edit-square-icon {
    margin: 17px 21px 0 0;
  }
`;
export const ListSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 52px;
  justify-content: center;
  margin: 10px;

  .ant-input-search {
    border: 1px solid #e8ecef;

    input {
      color: #98a9bc;
      font-size: 14px;
    }

    .ant-input-suffix {
      align-items: center;
      display: flex;
    }

    .anticon-search svg {
      color: #252631;
    }
  }

  .dash {
    background-color: #e8ecef;
    height: 1px;
    margin-top: 9px;
    width: 97%;
  }

  .ant-input-group-wrapper {
    border: none;

    .ant-btn-icon-only,
    .ant-input {
      height: 52px;
    }
  }
`;