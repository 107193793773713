import React from 'react';
import moment from 'moment';
import { AvatarWithFallbackOnError } from '@gtintel/platform-atoms';
import { DEFAULT_AVATAR, TWILIO_ADMIN_IDENTITY } from '@gtintel/platform-constants';
import ChatMessageAttachment from './ChatMessageAttachment';
import MessageBody from '../../../MessageBody';
import * as S from './styles';
const ChatMessage = ({
  attachmentUrls,
  message,
  user
}) => {
  const authorId = message.twilio.author;

  // did the current user send this message, or did they receive it?
  const isSent = authorId === String(user.id) || user.is_administrator && authorId === TWILIO_ADMIN_IDENTITY;
  const avatar = message.authorAvatar ?? DEFAULT_AVATAR;
  const twilio = message.twilio;
  // It's only possible to attach one file per message through our UI. (It
  // looks like you attach >1, but you're actually sending one message per
  // file, even though Twilio allows multiple files per message.)
  // So there are either 0 or 1 attachments:
  const attachment = twilio.attachedMedia.length ? twilio.attachedMedia[0] : null;
  let messageBody;
  if (attachment) {
    messageBody = <ChatMessageAttachment isSent={isSent} media={attachment} url={attachmentUrls.get(attachment.sid)} />;
  } else {
    messageBody = <MessageBody message={twilio} isSent={isSent} user={user} />;
  }
  const attributes = message.twilio.attributes;
  let labels = [];
  if (user.is_administrator && !isSent && attributes.nlp?.entities) {
    labels = attributes.nlp.entities.map(({
      label
    }) => {
      const labelLowerCase = label.toLowerCase();
      const labelHuman = label === 'GPE' ? 'GPE' : label.slice(0, 1) + labelLowerCase.slice(1);
      return <S.Label className={`label-${labelLowerCase}`}>{labelHuman}</S.Label>;
    });
  }
  return <S.Container data-testid="chat-message">
      <AvatarWithFallbackOnError className="chat-message-user-avatar" imageSrc={avatar} fallback={DEFAULT_AVATAR} alt="User avatar" />
      <S.InnerContainer>
        <S.Metadata>
          <S.AuthorName>{isSent ? 'You' : message.authorName}</S.AuthorName>
          <S.Timestamp>
            {moment(twilio.dateCreated).format('h:mm a')}
          </S.Timestamp>
          <div style={{
          flexGrow: 1
        }}></div>
          {labels}
        </S.Metadata>
        <S.Body isSent={isSent}>{messageBody}</S.Body>
      </S.InnerContainer>
    </S.Container>;
};
export default ChatMessage;