import styled from 'styled-components';
export const NamedEntity = styled.span`
  background-color: transparent;

  // NB the colors are all from the Tailwind CSS default palette,
  // using the '100' version of each color:
  // https://tailwindcss.com/docs/customizing-colors

  &.label-cardinal {
    background-color: #ccfbf1;
  }

  &.label-urgent {
    // red
    background-color: #fee2e2;
  }

  &.label-money {
    // orange
    background-color: #ffedd5;
  }

  &.label-gpe {
    // cyan
    background-color: #cffafe;
  }

  &.label-org {
    // pink
    background-color: #fbcfe8;
  }

  &.label-person {
    // lime
    background-color: #ecfccb;
  }

  &.label-moment {
    // violet
    background-color: #ddd6fe;
  }
`;