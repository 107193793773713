import React, { useState } from 'react';
import { Upload, message as antd_message } from 'antd';
import { UPLOADED_FILE_ALLOWED_EXTENSIONS } from '@gtintel/platform-constants';
import AttachIcon from '../../../AttachIcon';
import { FileUploadLabel, StyledForm, StyledTextareaAutosize, SendButton } from './styles';

// 1 MB = 1,000,000 bits/bytes. 150 MB is the hard limit imposed by Twilio.
const TWILIO_MAX_ATTACHMENT_SIZE = 150 * 1000000;
const isInvalidExtension = file => {
  const i = file.name.lastIndexOf('.');
  if (i === -1) return true;
  const extension = file.name.substring(i + 1, file.name.length);
  return !UPLOADED_FILE_ALLOWED_EXTENSIONS.includes(extension.toLowerCase());
};
const isFileTooBig = file => {
  return file.size >= TWILIO_MAX_ATTACHMENT_SIZE;
};
const sendMessage = async ({
  attachments,
  avatar,
  conversation,
  isAdmin,
  message,
  onFailure,
  projectId,
  startIndex,
  userId
}) => {
  const trimmedMessage = message.trim();
  if (!trimmedMessage && !attachments.length) return;
  let attempts = 0;
  let success = false;
  const messageAttrs = {
    author_avatar: avatar,
    project_id: projectId
  };
  if (isAdmin) {
    messageAttrs.startIndex = startIndex;
  }
  while (trimmedMessage && !success && attempts < 2) {
    let functionName = '';
    try {
      functionName = 'sendMessage';
      const result = await conversation.sendMessage(trimmedMessage, messageAttrs);
      functionName = 'updateLastReadMessageIndex';
      await conversation.updateLastReadMessageIndex(result);
      success = true;
    } catch (error) {
      console.trace({
        functionName,
        userId,
        channelId: conversation.sid,
        context: attempts > 0 ? 'retry' : 'primary',
        location: window.location
      });
      onFailure();
    }
    attempts += 1;
  }
  if (!success && attempts === 2) {
    antd_message.error('Could not send message. An unknown error occurred.');
    return;
  }
  if (attachments.length > 0) {
    if (attachments.some(a => isInvalidExtension(a.originFileObj))) {
      antd_message.error('Disallowed file extension');
      return;
    }
    if (attachments.some(a => isFileTooBig(a.originFileObj))) {
      antd_message.error('Files must be smaller than 150MB');
      return;
    }
    attachments.forEach(async attachment => {
      const formData = new FormData();
      formData.append('file', attachment.originFileObj);
      try {
        const result = await conversation.sendMessage(formData, messageAttrs);
        await conversation.updateLastReadMessageIndex(result);
      } catch (e) {
        antd_message.error(`Could not upload file: ${JSON.stringify(e)}`);
      }
    });
  }
};
const NewMessageForm = ({
  conversation,
  currentUser,
  isAdmin,
  isDisabled,
  onSendFailure,
  projectId,
  startIndex
}) => {
  const [attachments, setAttachments] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const submitForm = async () => {
    sendMessage({
      attachments,
      avatar: currentUser.avatar,
      conversation,
      isAdmin,
      message: newMessage,
      onFailure: onSendFailure,
      projectId: projectId,
      startIndex,
      userId: currentUser.id
    });
    setNewMessage('');
    setAttachments([]);
  };
  const onTextareaKeydown = e => {
    if (e.code === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitForm();
    }
  };
  return <StyledForm onFinish={submitForm}>
      <FileUploadLabel htmlFor="attach-file">
        <Upload disabled={isDisabled} fileList={attachments} onChange={({
        fileList
      }) => setAttachments(fileList)}>
          <AttachIcon />
        </Upload>
      </FileUploadLabel>
      <StyledTextareaAutosize disabled={isDisabled} value={newMessage} onChange={({
      target: {
        value
      }
    }) => setNewMessage(value)} onKeyDown={onTextareaKeydown} maxRows={5} placeholder="Start typing a message" />
      <SendButton disabled={isDisabled} htmlType="submit" className="btn-blue">
        SEND
      </SendButton>
    </StyledForm>;
};
export default NewMessageForm;