import React from 'react';
import * as S from './styles';
export const highlightNamedEntities = message => {
  const result = [];
  const attributes = message.attributes;
  let lastIndex = 0;
  let key = 0;
  const body = message.body;
  attributes.nlp.entities.forEach(entity => {
    const {
      start_char,
      end_char,
      label
    } = entity;

    // Add any unlabelled text before this entity
    if (start_char > lastIndex) {
      result.push({
        content: body.substring(lastIndex, start_char),
        key,
        label: null
      });
      key++;
    }
    result.push({
      content: body.substring(start_char, end_char),
      key,
      label
    });
    key++;
    lastIndex = end_char;
  });

  // Add any remaining unlabelled text after the last entity
  if (lastIndex < body.length) {
    result.push({
      content: body.substring(lastIndex),
      key,
      label: null
    });
    key++;
  }
  return result;
};
const MessageBody = ({
  isSent,
  message,
  user
}) => {
  const attributes = message.attributes;
  if (!user.is_administrator || isSent || !attributes?.nlp?.entities) {
    return <div>{message.body}</div>;
  }
  const parts = highlightNamedEntities(message);
  return <div>
      {parts.map(({
      content,
      key,
      label
    }) => {
      const labelClass = label ? `label-${label.toLowerCase()}` : '';
      return <S.NamedEntity key={key} className={labelClass}>
            {content}
          </S.NamedEntity>;
    })}
    </div>;
};
export default MessageBody;