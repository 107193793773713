import styled from 'styled-components';
export const Container = styled.div`
  border-bottom: 1px solid #e8ecef;
  display: flex;
  font-size: 16px;
  height: 56px;
  width: 100%;

  .ant-avatar-image {
    margin-top: 10px;
    margin-left: 17px;
    width: 38px;
    height: 38px;
  }
`;
export const Title = styled.div`
  font-size: 16px;
  margin-left: 12px;
  margin-top: 14px;
`;
export const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 26px;
  margin: 2px 16px 0 auto;
`;