import styled from 'styled-components';
export const Container = styled.div`
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;

  ${({
  isPopup
}) => isPopup ? `
      padding: 24px 16px 0 16px;
    ` : `
      max-height: calc(100vh - 275px);
      padding-left: 17px;
      padding-top: 20px;
    `}
`;
export const DateSeparator = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 6px 0 7px;
  width: 100%;
`;
export const DateSeparatorLine = styled.div`
  flex-grow: 1;
  position: relative;

  &:first-child {
    margin-right: 15px;
  }

  &:last-child {
    margin-left: 15px;
    margin-right: 15px;
  }

  &::before {
    background-color: #94a3b8;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
  }
`;
export const Date = styled.div`
  color: #64748b;
  font-weight: bold;
  white-space: nowrap;
`;