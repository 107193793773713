import { Collapse } from 'antd';
import styled, { css } from 'styled-components';
const {
  Panel
} = Collapse;
export const Container = styled(Collapse)`
  font-family: Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
  font-weight: 400;

  .ant-collapse-header {
    display: flex;
    align-items: center;
    min-height: 80px;
    background-color: #f8f8f8;
    ${props => props.isActive && css`
        background-color: #f0f0f0;
      `};
    transition: all 0.4s ease;
  }
  .ant-collapse-header:hover {
    background-color: #f0f0f0;
  }
  .ant-collapse-content-box {
    padding: 0;

    li.ant-list-item.read::before {
      content: '';
      width: 0;
    }
  }
`;
export const Header = styled(Panel)``;