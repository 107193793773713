import React from 'react';
import { Modal } from 'antd';
import { Document, Page, StyleSheet, Font, PDFViewer, Text } from '@react-pdf/renderer';
import { TWILIO_ADMIN_IDENTITY } from '@gtintel/platform-constants';
const styles = StyleSheet.create({
  back: {
    width: '100%',
    height: '80vh'
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  name: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 10
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontWeight: 600,
    letterSpacing: 1
  },
  imageLogo: {
    maxWidth: '48%',
    minHeight: 100,
    width: '48%',
    flexBaiss: '50%'
  },
  image: {
    maxWidth: '24%',
    minHeight: 50,
    width: '24%',
    flexBaiss: '25%'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  content: {
    textAlign: 'justify',
    marginLeft: 12,
    fontSize: 12
  },
  textReport: {
    marginTop: 12,
    marginLeft: 12,
    marginBottom: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontWeight: 700
  },
  textContent: {
    textAlign: 'justify',
    marginLeft: 12,
    fontSize: 10,
    marginBottom: 7
  },
  viewFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  flex: {
    display: 'flex'
  }
});
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});
const renderMessages = (messages, currentUserId) => messages.map((message, i) => {
  const authorId = message.twilio.author;
  const style = {
    marginBottom: '10px',
    width: '70%',
    padding: '10px',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5
  };
  if (authorId === String(currentUserId) || authorId === TWILIO_ADMIN_IDENTITY) {
    style['marginLeft'] = '30%';
    style['textAlign'] = 'right';
    style['backgroundColor'] = '#4D7CFE';
    style['color'] = 'white';
  } else {
    style['textAlign'] = 'left';
    style['backgroundColor'] = '#F2F4F6';
  }
  return <Text key={i} style={style}>
        {message.twilio.body}
      </Text>;
});
const ChatPdf = ({
  currentUserId,
  isVisible,
  messages,
  onCancel,
  onOk,
  title
}) => <Modal style={{
  top: 20,
  width: '80%'
}} className="Preview PDF" visible={isVisible} onOk={onOk} onCancel={onCancel} footer={null}>
    <PDFViewer style={styles.back}>
      <Document>
        <Page style={styles.body} size="A4">
          <Text style={styles.header} fixed>
            {title}
          </Text>
          {renderMessages(messages, currentUserId)}
          <Text style={styles.pageNumber} render={({
          pageNumber,
          totalPages
        }) => `${pageNumber} / ${totalPages}`} fixed />
        </Page>
      </Document>
    </PDFViewer>
  </Modal>;
export default ChatPdf;