import styled from 'styled-components';
export const Container = styled.div`
  border: 1px solid #f8fafb;
  background-color: #ffff;
  margin-left: 22px;
  display: flex;
  flex-direction: column;
  height: 100%;
  white-space: break-spaces;
`;
export const CenterText = styled.p`
  text-align: center;
`;